import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import backgroundimg from '../assets/background3.png';

function Home() {
  return (
    <>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <h5>MAHARERA No. :-A52100022588</h5>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <h5>email : vipdave@gmail.com</h5>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img src={backgroundimg} className="img-fluid" alt="Background"></img>
          </div>
        </div>
        <br></br>
        <div className="row">
        <div className="col-lg-4"></div>
            <div className="col-lg-4 col-md-12 col-sm-12">
            <p>Whether you’re looking to buy, sell or rent, we are here to support you make the right choice. 
            We served thousands of individuals and families with their home needs</p>
            </div>
            <div className="col-lg-4"></div>
        </div>
        <br></br>
      </div>

        
      <div className="container-fluid">
        <div className="row" style={{ backgroundColor: "black" }}>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <p style={{ color: "white" }}>Terms & Support</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4"></div>
          <div className="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        
      </div>
    </>
  );
}

export default Home;
